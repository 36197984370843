import type {
  DPDebugFetchRequestMessage,
  DPDebugFetchResponseMessage,
  DPDebugInspectComponentMessage,
} from "../types";

/**
 * A debug client for use in the web application being debugged. This
 * communicates with debug host.
 */
export const debugClient = {
  _instrumentedFetchId: 0,

  // https://stackoverflow.com/a/326076
  isDebug: () => {
    return (
      typeof window !== "undefined" &&
      window.self !== window.top &&
      window.top?.location.pathname.includes("/debug")
    );
  },

  inspectComponent(data: {
    input?: string;
    name: string;
    path?: string;
    state?: string;
  }) {
    debugClient.sendMessage({
      data,
      type: "dp-debug-inspect-component",
    });
  },

  async instrumentedFetch(input: RequestInfo | URL, init?: RequestInit) {
    const id = debugClient._instrumentedFetchId++;

    const requestMessage: DPDebugFetchRequestMessage = {
      data: {
        init: init
          ? {
              body: init.body,
              headers: init.headers,
              method: init.method,
            }
          : null,
        input,
      },
      meta: {
        id,
      },
      type: "dp-debug-fetch-request",
    };

    debugClient.sendMessage(requestMessage);

    const start = Date.now();

    const response = await fetch(input, init);

    let responseJson: any = null;
    let responseText: any = null;

    try {
      responseJson = await response.clone().json();
    } catch (error) {
      console.error(error);
    }

    try {
      responseText = await response.clone().text();
    } catch (error) {
      console.error(error);
    }

    const responseMessage: DPDebugFetchResponseMessage = {
      data: {
        headers: Array.from((response.headers as any).entries()),
        json: responseJson,
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        text: responseText,
      },
      meta: {
        id,
        responseTime: Date.now() - start,
      },
      type: "dp-debug-fetch-response",
    };

    debugClient.sendMessage(responseMessage);

    return response;
  },

  sendMessage(
    message:
      | DPDebugFetchRequestMessage
      | DPDebugFetchResponseMessage
      | DPDebugInspectComponentMessage,
  ) {
    parent.postMessage(message);
  },
};
